.article-content {
  background: #fff;
  padding: 52px 66px;
  border: 1px solid #bcc6d0;

  @media only screen and (max-width: 800px) {
    padding: 28px 34px;
  }
}
.article-content-text {
  padding-bottom: 7%;
  & img {
    max-width: 100%;
  }
}

.article-content-update {
  text-align: center;
  color: #5b6f83;
  font-size: 13.5px;
  line-height: 20px;
  font-style: italic;
  margin-top: 22px;
  font-weight: 100;
}
