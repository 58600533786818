@use "./PopularPublications";
@use "./Categories";

.landing {
    max-width: 900px;
    margin: 0 auto;
    @media only screen and (max-width: 1096px) {
        max-width: 90%
      }

}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}