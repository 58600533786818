.article-rate {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1%;
  justify-content: center;
  padding-top: 10%;

  @media only screen and (max-width: 340px) {
    flex-direction: column;
    gap: 10px;
  }
}

.article-rate-text {
  & h6 {
    font-size: 16.2px;
  }
}

.article-rate-button {
  background-color: #e1ae00;
  color: #fff;
  font-size: 13.5px;
  line-height: 28px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  border-radius: 60px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    transform: scale(1.075);
    transition: transform 0.15s linear;
  }
}
.article-rate-modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 500px;
  padding: 22px 44px 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
}

.article-rate-modal-text {
  & textarea {
    border: 0;
    outline: none;
    width: 100%;
    height: 100px;
    resize: none;
  }
}

.article-rate-modal-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  width: 100%;
}

.article-rate-modal-actions-send {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #e1ae00;
  font-weight: 600 !important;
  border: 1px solid transparent;
  font-size: 13.05px;
  text-align: center;
  letter-spacing: 0.1px;
  cursor: pointer;
  height: 37px;
  padding: 0 12px;
  border-radius: 80px;
  width: 70%;
}
.article-rate-modal-actions-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b6f83;
  border: 1px solid transparent;
  border-color: #5b6f83;
  font-size: 13.05px;
  text-align: center;
  line-height: 37px;
  cursor: pointer;
  height: 37px;
  padding: 0 12px;
  border-radius: 80px;
  width: 30%;
}

@keyframes example {
  0% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
  }
}

.article-rate-thanks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  animation-name: example;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  & h6 {
    color: #5b6f83;
    font-size: 18.4px;
    font-style: italic;
    font-weight: 700 !important;
    opacity: 0.8;
  }

  & img {
    max-width: 25px;
  }
}
