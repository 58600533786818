.not-found-content {
  background-color: #fff;
  padding-top: 36px;
  padding-bottom: 34px;
}

.not-found-content-primary-icon {
  & img {
    max-width: 60px;
  }
}

.not-found-content-primary {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  text-align: right;
  @media only screen and (max-width: 780px) {
    flex-direction: column;
    text-align: center;
  }
}

.not-found-content-primary-divider {
  width: 1px;
  background-color: rgba(188, 198, 208, 0.5);
  vertical-align: middle;
  margin: 4px 26px 0 30px;
  height: 50px;
  @media only screen and (max-width: 780px) {
    display: none;
  }
}

.not-found-content-primary-text-bold {
  font-size: 18.9px;
  line-height: 28px;
  font-weight: 700 !important;
}

.not-found-content-primary-text-regular {
  font-size: 15.3px;
  line-height: 24px;
  margin-top: 5px;
}

.not-found-content-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  color: #5b6f83;
  gap: 10px;
  padding-top: 3%;

  @media only screen and (max-width: 440px) {
    flex-direction: column;
    padding-top: 10%;
  }
}

.not-found-content-buttons-home,
.not-found-content-buttons-contact {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 10px;
  border: 1px solid #bcc6d0;
  padding: 6px 14px;
  cursor: pointer;
}

.not-found-content-buttons-text {
  font-weight: bold;
}
