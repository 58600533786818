.footer-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.footer-title {
  margin-top: 35px;
  & h3 {
    margin: 0;
    font-weight: 700 !important;
    font-family: "Crisp Graphik Medium", sans-serif;
    text-align: center;
  }
}

.footer-text {
  color: #5b6f83;
  margin-bottom: 20px;
}

.footer-buttons {
  display: flex;
  flex-direction: row-reverse;
  color: #ffffff;
  gap: 10px;
  margin-bottom: 35px;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
}

.footer-buttons-chat,
.footer-buttons-mail {
  display: flex;
  justify-content: center;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #1972f5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 6px 14px;
  cursor: pointer;

  & .footer-buttons-chat-text,
  .footer-buttons-mail-text {
    font-weight: bold;
  }
}

.footer-copyright {
  text-align: center;
  opacity: 0.5;
  color: #5b6f83;
  font-size: 12px;
  margin-top: 25px;
  margin-bottom: 25px;
}
