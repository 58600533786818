.navbar {
  background: #fff;
  border-bottom: 1px solid #d9e1e8;
}

.navbar-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1300px;
  margin: 0 auto;
}

.navbar-title {
  & h1 {
    font-size: 18px;
    line-height: 24px;
    min-height: 24px;
    max-height: 48px;
    letter-spacing: 0.1px;
    text-align: center;
  }
}

.navbar-return {
  display: flex;
  cursor: pointer;
}
.navbar-return-button {
  background-color: #1467ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  font-size: 14.4px;
  font-weight: bold;
  color: #fff;
  border-radius: 2px;

  @media only screen and (max-width: 860px) {
    display: none;
  }
}

.navbar-return-icon {
  margin-left: 9px;
  color: #5b6f83;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.article-no-translation {
  top: 0; /* Position it at the top */
  left: 0; /* Start from the left edge */
  width: 100%; /* Full width */
  background-color: yellow; /* Yellow background color */
  color: black; /* Text color for visibility */
  padding: 10px; /* Padding for spacing */
  text-align: center; /* Center the text */
  z-index: 1000; /* Ensure it appears above other elements */
}
