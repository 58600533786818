.categorie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 3px;
  padding: 18px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
  max-height: 200px;
  border: 0;
  cursor: pointer;
  position: relative;
  z-index: -2000;
  &:hover {
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
    border-color: #bcc6d0;
    transition: all 0.15s linear;
    transition-property: border-color, box-shadow, transform;
  }
}

.categorie-image {
  & img {
    max-width: 120px;
    max-height: 80px;
    margin: 8px auto 24px;
  }
}

.categorie-button {
  background-color: #1467ff;
  color: white;
  font-weight: 700;
  padding: 5px 12px;
  border-radius: 2px;
  margin-bottom: 20px;
  text-overflow: ellipsis; /* Adds ellipsis ('...') when the text overflows */
  display: block; /* Ensure the button is displayed as a block element */
  width: 100%; /* Ensure the button takes the full width of its container */
  text-align: center; /* Center the text inside the button */
  box-sizing: border-box;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.categorie-text {
  text-align: center;
  font-weight: 500;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.categorie-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: yellow;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #000;
}
