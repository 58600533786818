.publication {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 15px 18px;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  cursor: pointer;
  min-height: 48px;
  position: relative;
  z-index: -2000; /* Ensure it has a high z-index */

  &:hover {
    border-color: #bcc6d0;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
    transition: all 0.15s linear;
    transition-property: border-color, box-shadow, transform;
  }
}

.publication-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.publication-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: yellow;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #000;
}
