.category-item {
  color: #5b6f83;
  background-color: #fff;
  padding: 20px 42px 22px;
  border: 1px solid #bcc6d0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
  cursor: pointer;
  position: relative;
  z-index: -2000;
  max-width: 417px;
  margin: 0 80px;
  @media only screen and (max-width: 860px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 600px) {
    margin: 0;
  }
}

.category-item-title {
  color: #141414;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
  align-self: end;
}
.category-item-content {
  display: flex;
  justify-content: space-around;
}

.category-item-content-text {
  text-align: right;
  font-size: 13.95px;
  line-height: 20px;
  max-width: 70%;

  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-line-clamp: 2; /* number of lines to show */

  line-clamp: 2;

  -webkit-box-orient: vertical;
  & img {
    display: none !important;
  }
  & iframe {
    display: none !important;
  }

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
}
.category-item-content-rating-icons {
  display: flex;
  margin-top: 12%;
}

.category-item-content-rating-icon {
  display: inline-block;
  width: 3px;
  height: 12px;
  margin: 0 1px 0 2px;
  background-color: #e1ae00;
}

.disabled {
  opacity: 0.15;
}

.category-item-content-rating {
  text-align: right;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.category-item-content-rating-text {
  font-size: 12.6px;
  line-height: 16px;
  font-weight: 600;
  color: #141414;
  text-align: right;
}

.category-item-separator {
  background-color: rgba(188, 198, 208, 0.5);
  margin: 0 28px;
  width: 1px;
  height: 40px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.category-no-translation {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: yellow;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #000;
}
