.aside {
  padding: 10px 20px 16px 2px;
  padding-left: 30px;
  text-align: end;
}

.aside-title {
  padding-bottom: 5%;
}

.aside-link {
  padding: 8px 0;
  color: #5b6f83;
  font-size: 13.5px;
  line-height: 18px;
  letter-spacing: 0.4px;
  cursor: pointer;
  &:hover {
    color: #141414;
    text-decoration: underline;
    transition: color .15s linear;
  }
}
