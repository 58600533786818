a {
  all: unset;
}

header {
  font-family: "Proxima Nova Regular";
  position: relative;
  padding-bottom: 30px;
  position: relative;
}
.header-main {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 28px;
}

.header-main-logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 34px;
  font-weight: bold;
  color: white;
  padding-left: 10px;
  & img {
    max-width: 45px;
    max-height: 45px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 15%;
  }

  & .header-main-logo-text {
    font-family: "Crisp Graphik Medium", sans-serif;
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
}

.header-main-actions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.header-main-actions-go {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  padding: 6px 15px;
  border-radius: 6px;
  cursor: pointer;

  @media only screen and (max-width: 530px) {
    display: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.15s linear;
    transition-property: background, box-shadow, transform;
  }
}

.header-title {
  color: white;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.1px;
  margin-bottom: 17px;
  font-weight: 700 !important;
  font-family: "Crisp Graphik Medium", sans-serif;
}

.header-search {
  max-width: 500px;
  margin: 0 auto;

  @media only screen and (max-width: 530px) {
    max-width: 90%;
    margin: 0 auto;
  }
}

.header-search-input {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: row;
  background-color: #fff;
  z-index: 1000;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  & input {
    z-index: 100;
    height: 56px;
    line-height: 56px;
    font-size: 16.2px;
    letter-spacing: -0.1px;
    padding: 0 68px;
    background: #fff;
    border: 0;
    width: 100%;
    outline: none;
    text-align: center;
    border-radius: 8px;

    &::placeholder {
      text-align: center;
      color: #595959;
      font-weight: bold;
    }
  }
}

.header-search-results {
  background-color: #fff;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid #bcc6d0;
  position: absolute;
  width: 100%;
}

.header-search-results-item {
  text-align: center;
  font-size: 15.3px;
  font-weight: 600 !important;
  padding: 10px 30px;
  cursor: pointer;
  z-index: 2000; /* Ensure it has a high z-index */
  &:hover {
    background-color: #e1ae00;
  }
}

.header-search-no-results-item {
  text-align: center;
  font-size: 15.3px;
  font-weight: 600 !important;
  padding: 10px 30px;
  color: #5b6f83;
  font-style: italic;
}

.header-main-actions-languages {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    height: 38px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
      transition: all 0.15s linear;
      transition-property: background, box-shadow, transform;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
      color: #fff;
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    top: 100px;
    left: 200px !important;
  }
}
