.pouplar-publications-title {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;

  @media only screen and (max-width: 480px) {
    display: none;
  }
}
.pouplar-publications-title-icon {
  color: grey;
}

.pouplar-publications-title-text {
  color: black;
  font-size: 20px;
  font-weight: bold;
}
.pouplar-publications-articles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-rows: 6;

  @media only screen and (max-width: 780px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 20px;
  }

  @media only screen and (max-width: 480px) {
    display: none;
  }
}
